import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../atoms/inputs/Button';
import Headline from '../atoms/typography/Headline';

function SubmissionSuccessful() {
  return (
    <div className="mx-[24px] mb-[40px] lg:mb-[120px]">
      <Headline
        headerText="Successfully Submitted!"
        className="text-3xl md:text-4xl lg:text-5xl text-highlight font-light lg:leading-[62.5px] mb-4"
      />
      <p>
        Thanks for submitting a hangout. To be part of our beta release, please request access on
        {' '}
        <a href="/" className="text-highlight underline">flockx.io</a>
      </p>
    </div>
  );
}

export default function HangoutEntry() {
  const [isLoading, setIsLoading] = useState(false);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

  const inputStyles = 'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const name = e.target.name.value;
    const email = e.target.email.value;
    const business = e.target.business.value;
    const community = e.target.community.value;

    try {
      const baseURL = '/.netlify/functions/hangout-entry';
      const url = new URL(baseURL, window.location.origin);

      url.searchParams.append('name', name);
      url.searchParams.append('email', email);
      url.searchParams.append('business', business);
      url.searchParams.append('community', community);

      const response = await fetch(url, {
        method: 'POST',
      });

      if (response.ok) {
        e.target.reset();
        toast('Request submitted.');
        setSubmissionSuccessful(true);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div
      id="hangout-form"
      className="relative z-10 text-center w-full bg-white px-0 lg:px-10 py-4 xl:py-8 rounded-3xl"
    >
      {submissionSuccessful && <SubmissionSuccessful />}
      {!submissionSuccessful && (
      <>
        <div className="max-w-3xl text-center mx-auto mb-8 lg:mb-8">
          <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4">
            <span className="text-highlight">
              Share your Hangout
            </span>
          </h3>

          <p className="text-lg lg:text-xl xl:text-2xl max-w-lg mx-auto">
            Make your print on the world by sharing details of a community hangout.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="mx-auto max-w-lg">
          <div className="flex flex-col  items-start justify-start w-full">
            <input
              name="name"
              type="text"
              required
              className={`${inputStyles} mb-4`}
              placeholder="Name"
            />

            <input
              name="email"
              type="email"
              required
              className={`${inputStyles} mb-4`}
              placeholder="Email"
            />

            <input
              name="community"
              type="text"
              required
              className={`${inputStyles} mb-4`}
              placeholder="Community Interest (Live Music, Cocktails...)"
            />
            <input
              name="business"
              required
              className={`${inputStyles} mb-4`}
              placeholder="Business Name or Spot"
            />
          </div>
          <p
            className="my-3 text-xs lg:text-base sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset] mx-auto"
          >
            By providing your information, you agree to our
            {' '}
            <a href="/legal/terms" target="_blank" className=" text-highlight">
              terms of service
            </a>
            .
          </p>
          <div className="mx-auto flex items-center justify-center h-full relative">
            <Button
              type="submit"
              label="Submit"
              className="min-w-[200px]"
              isLoading={isLoading}
              minWidth={213}
            />
          </div>
        </form>
      </>
      )}
    </div>
  );
}
