import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import HangoutEntry from '../components/organisms/HangoutEntry';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import Spacer from '../components/atoms/utility/Spacer';

// Local function
function HeroContent() {
  return (
    <div className="-mt-[15%] sm:mt-[5%] lg:mt-0 text-center sm:text-left">
      <PrimaryHeader
        headerText="Share Community Hangouts"
        className="text-4xl sm:text-[40px] md:text-3xl lg:text-3xl xl:text-[64px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize md:w-1/2 mx-auto lg:mr-auto sm:ml-0 lg:ml-0 font-light"
      />
      <SubHeader
        headerText="Let other community members know where your community is hanging out!"
        className="mb-4 md:mb-4 lg:mb-4 text-white mx-auto font-normal max-w-[300px] sm:max-w-sm lg:max-w-lg sm:mx-0"
      />
      <a
        href="#hangout-form"
        onClick={() => window.scrollBy({ top: 800, behavior: 'smooth' })}
        className="mt-[12px] md:mt-[36px] mb-[36px] cursor-pointer w-[211px] h-10 px-4 py-2.5 md:w-[279px] md:h-[54px] md:py-[9px] bg-gradient-to-b from-indigo-500 to-purple-500 rounded-[28.50px] justify-center items-center gap-[74px] inline-flex"
      >
        <div className="text-center text-white text-base md:text-xl font-normal font-['DM Sans'] leading-relaxed tracking-wide">
          Share your Favorites
        </div>
      </a>
    </div>
  );
}

export default function HangoutPage({ data }) {
  const { heroImg } = data;
  const { mobileHeroImg } = data;
  return (
    <BaseLayout
      heroContent={HeroContent}
      heroImg={heroImg}
      mobileHeroImg={mobileHeroImg}
    >
      <SEO
        title="Discover Community Hangout Spots | The Unlimited Hangout App"
        description="Find the best hangout spots near you with our app. Explore community favorites and places to hang out, all in one place. Start your adventure today!"
        customSeoImage={heroImg}
      />
      <Spacer sizeY={1} />
      <HangoutEntry />
    </BaseLayout>
  );
}

export const query = graphql`
  query CareersPageQuery {
    heroImg: file(name: { eq: "hangout-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImg: file(name: { eq: "hangout-hero-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

HangoutPage.propTypes = {
  data: PropTypes.shape({
    heroImg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    mobileHeroImg: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        // eslint-disable-next-line react/forbid-prop-types
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
